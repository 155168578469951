@font-face {
    font-family: 'craftregular';
    src: url(./craft-regular.TTF);
}

@font-face {
    font-family: 'craftsemibold';
    src: url(./craft-semibold.TTF);
}

.craftregular {
    font-family: 'craftregular', sans-serif;
}

.craftsemibold {
    font-family: 'craftsemibold', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'craftregular', sans-serif;
    outline: none;
    scroll-behavior: smooth;
}

input {
    border: 1px solid transparent;
}

select option {
    background: #3e352f94;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.card:hover .to-top {
    top: -100%;
}

.dragger-scroll::-webkit-scrollbar {
    width: 1px;
}


.div1 {
    grid-area: 1 / 1 / 3 / 4;
}

.div2 {
    grid-area: 1 / 4 / 3 / 6;
}

.div3 {
    grid-area: 3 / 1 / 5 / 3;
}

.div4 {
    grid-area: 3 / 3 / 5 / 6;
}

.div5 {
    grid-area: 5 / 1 / 7 / 4;
}

.div6 {
    grid-area: 5 / 4 / 7 / 6;
}

.scrollbar-hide::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}

.liv1,
.liv2 {
    width: 38%;
}

.liv4,
.liv3 {
    width: 60%;
}

footer {
    background-image: url('./img/footer.png');
    background-size: cover;
}

.bg-slide-item {
    background-image: url('./img/bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center
}

.bg-slide-blue {
    background-image: url('./img/bgcharge.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center
}



.bg-charger {
    background-image: url('./img/bgcharger.jpg');
    background-size: cover
}

.bg-truck {
    background-image: url('./img/bgtruck.jpg');
    background-size: cover
}

.mapofcharge {
    filter: invert(1);
    opacity: .5;
}

.bgofslider {
    background: rgb(66, 96, 124);
    background: linear-gradient(0deg, rgba(66, 96, 124, 1) 0%, rgba(66, 96, 124, 1) 48%, rgba(173, 216, 255, 0.19849658613445376) 50%, rgba(173, 216, 255, 0.2) 50%, rgba(255, 255, 255, 0) 50%);
}

.bgofslidertruck {
    background: rgb(66, 96, 124);
    background: linear-gradient(0deg, #5E5650 0%, #5E5650 48%, rgba(100, 100, 100, 0.198) 50%, rgba(100, 100, 100, 0.198) 50%, rgba(255, 255, 255, 0) 50%);
}

.welcome {
    background-size: 160vh !important;
}


.notify::after {
    width: 3vw;
    height: 3vw;
    background-color: #acc2da;
    position: absolute;
    content: '';
    bottom: 40%;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    right: 1vw;
    z-index: -1;
}

.notify.top::after {
    top: 40%;
}

.afters::after {
    content: '';
    position: absolute;
    width: 3.5vw;
    height: 3.5vw;
    border: .2vw solid;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    right: 40%;
    transition: .4s;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -ms-transition: .4s;
    -o-transition: .4s;
}

.afters:hover::after {
    right: -.5vw;
}

ul#active {
    top: 16vw !important;
}

.active__nav {
    opacity: 1 !important;
}

.active__nav::after {
    border: .4vw solid aqua;
    position: absolute;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    content: '';
    left: -1.4vw;
    top: .5vw;
    border-left-color: transparent;
    border-bottom-color: transparent;
}

.bg-truck .active__nav::after {
    border: .4vw solid #FECE00;
    border-left-color: transparent;
    border-bottom-color: transparent;
}

.contact-car img {
    animation: contactCar infinite 5s ease-in-out;
    -webkit-animation: contactCar infinite 5s ease-in-out;
}


@keyframes contactCar {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -o-transform: rotate(-360deg);
    }
}

#mobile_three {
    background-image: url('./img/mobile360.png');
    background-size: cover;
}

.left {
    clip-path: polygon(98% 0, 68% 46%, 100% 47%, 78% 100%, 0 100%, 0 0);
}

.open-image:checked~img {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    padding: 4vw;
    background-color: rgba(0, 0, 0, 0.692);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.cursor-search {
    cursor: url(img/search.png), auto;
}
.hover-icon{
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;

}
.hover-icon:hover{
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
}
@media(min-width: 768px) {

    .welcome:hover {
        width: 75%;
    }

    .welcome:nth-child(2) {
        clip-path: polygon(32% 0, 100% 0, 100% 100%, 10% 100%, 28% 46%, 0 47%);
    }

    .welcome:nth-child(2):hover {
        z-index: 40;
    }

    .welcome:nth-child(2):hover~.absolute__home {
        left: 20%;
    }

    .welcome:nth-child(1):hover~.absolute__home {
        left: 45%;
    }

    .btn-rotate {
        background-image: url('./img/btn.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center
    }

    .btn-rotate:hover {
        background-image: url('./img/btnhover.png');
        color: #1C3751;
    }

    .animate-slider {
        animation: animator .5s both;
        -webkit-animation: animator .5s both;
    }

    @keyframes animator {
        from {
            transform: translateX(30vw) scale(.3);
            -webkit-transform: translateX(30vw) scale(.3);
            -moz-transform: translateX(30vw) scale(.3);
            -ms-transform: translateX(30vw) scale(.3);
            -o-transform: translateX(30vw) scale(.3);
            opacity: 0;
        }

        to {
            transform: translateX(0vw) scale(1);
            -webkit-transform: translateX(0vw) scale(1);
            -moz-transform: translateX(0vw) scale(1);
            -ms-transform: translateX(0vw) scale(1);
            -o-transform: translateX(0vw) scale(1);
            opacity: 1;
        }
    }
}

@media(max-width:768px) {
    .afters::after {
        width: 6.5vw;
        right: -1.5vw;
        height: 6.5vw;
    }

    .left {
        clip-path: polygon(0 0, 0 89%, 44% 100%, 43% 77%, 100% 100%, 100% 0, 100% 0);
    }

    .welcome {
        background-size: 170% !important
    }

}

@tailwind base;
@tailwind components;
@tailwind utilities;